import { DetalleFacturaSV } from "./DetalleFacturaSV";
import { TransactionFEL } from "./TransactionFEL";

export class FacturaSV {
    constructor(
        public id: number,
        public tipo: string,
        public numeroFactura: string,
        public tipoFacturacion: string,
        public codigoCliente: string,
        public nitCliente: string,
        public razonSocialCliente: string,
        public ubicacionVenta: string,
        public codigoBodega: string,
        public descripcionBodega: string,
        public codigoVendedor: string,
        public descripcionVendedor: string,
        public tipoVendedor: string,
        public fechaFacturacion: string,
        public conteoVehiculos: string,
        public nombreCompania: string,
        public correo1: string,
        public direccion: string,
        public cajero: string,
        public tipoVenta: string,
        public codigoPostal: string,
        public actividadEconomica: string,
        public estadoFEL: string,
        public codigoGeneracion: string,
        public fechaEmision: string,
        public identificador: string,
        public numeroControl: string,
        public selloRecepcion: string,
        public rutaPDF: string,
        public motivoAnulacion: string,
        public codigoGeneracionAnulacion: string,
        public fechaEmisionAnulacion: string,
        public selloRecepcionAnulacion: string,
        public numeroControlAnulacion: string,
        public rutaPDFAnulacion: string,
        public nrc: string,
        public empresa: string,
        public jsonEmision: string,
    ) {
    }

    nombreTipo: string;
    nombreEstadoFEL: string;
    colorEstadoFEL: string;
    fechaFacturacionFormato: string;
    fechaFacturacionDate: Date;
    detalles: DetalleFacturaSV[];
    ultimaTransaccion: TransactionFEL;
    aplicarRetencion: boolean;
    numeroReferencia: string;
}